<template>
  <div class="factory-alarm">
    <Common-Shell
      type="factoryAlarm"
      :search-option="searchOption"
    ></Common-Shell>
  </div>
</template>

<script>
import CommonShell from "../comps/CommonShell.vue";
export default {
  name: "factoryAlarm",
  components: { CommonShell },
  props: {},
  data() {
    return {};
  },
  computed: {
    searchOption() {
      return {};
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.factory-alarm {
  height: 100%;
}
</style>
